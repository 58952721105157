import { numFormat } from '@/utils/dealNumber'
export const columns = [
  {
    title: '日期',
    dataIndex: 'date',
    fixed: true,
    scopedSlots: { customRender: 'date' },
    customRender: (text, record, index) => {
      return record.date || '--'
    }
  },
  {
    title: '应用',
    width: 140,
    dataIndex: 'appName',
    scopedSlots: { customRender: 'appName' }
  },
  {
    title: '广告位',
    width: 160,
    dataIndex: 'placeName',
    scopedSlots: { customRender: 'placeName' }
  },
  {
    title: '广告平台',
    width: 140,
    dataIndex: 'platName',
    customRender: (text, record, index) => {
      if (record.type !== 'total') {
        return record.platName || '--'
      }
    }
  },
  {
    title: '平台账户',
    width: 140,
    dataIndex: 'platAccountName',
    customRender: (text, record, index) => {
      if (record.type !== 'total') {
        return record.platAccountName || '--'
      }
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 200,
    scopedSlots: { customRender: 'action' }
  },
  {
    title: '状态',
    width: 120,
    scopedSlots: { customRender: 'auditSts' },
    dataIndex: 'auditSts'
  }
]
// 应用
//    媒体指标
export const mediaApplication = [
  {
    title: '媒体日活',
    dataIndex: 'settleDau',
    width: 120,
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleDau || record.settleDau === 0) {
        return numFormat(record.settleDau, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体人均收益',
    dataIndex: 'settleArpu',
    width: 120,
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleArpu || record.settleArpu === 0) {
        return numFormat(record.settleArpu, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体请求',
    width: 120,
    scopedSlots: { customRender: 'settleRequest' },
    dataIndex: 'settleRequest',
    customRender: (text, record, index) => {
      if (record.settleRequest || record.settleRequest === 0) {
        return record.settleRequest
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体填充',
    width: 120,
    slots: { title: 'settleResponse' },
    dataIndex: 'settleResponse',
    customRender: (text, record, index) => {
      if (record.settleResponse || record.settleResponse === 0) {
        return numFormat(record.settleResponse, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体填充率',
    width: 120,
    slots: { title: 'settleRequestFilledRate' },
    dataIndex: 'settleRequestFilledRate',
    customRender: (text, record, index) => {
      if (record.settleRequestFilledRate || record.settleRequestFilledRate === 0) {
        return numFormat(record.settleRequestFilledRate, 3, '') + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体展示',
    width: 120,
    scopedSlots: { customRender: 'settleImpress' },
    dataIndex: 'settleImpress',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleImpress || record.settleImpress === 0) {
        return numFormat(record.settleImpress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击',
    width: 120,
    scopedSlots: { customRender: 'settleClick' },
    dataIndex: 'settleClick',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleClick || record.settleClick === 0) {
        return numFormat(record.settleClick, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击率',
    width: 120,
    checked: false,
    scopedSlots: { customRender: 'settleClickRate' },
    dataIndex: 'settleClickRate',
    customRender: (text, record, index) => {
      if (record.settleClickRate || record.settleClickRate === 0) {
        return record.settleClickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体eCPM',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleEcpm' },
    dataIndex: 'settleEcpm',
    customRender: (text, record, index) => {
      if (record.settleEcpm || record.settleEcpm === 0) {
        return numFormat(record.settleEcpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体收益',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleRevenue' },
    dataIndex: 'settleRevenue',
    customRender: (text, record, index) => {
      if (record.settleRevenue || record.settleRevenue === 0) {
        return numFormat(record.settleRevenue, 3, '')
      } else {
        return '--'
      }
    }
  }
]
//    数据指标
export const Application = [
  {
    title: '日活',
    dataIndex: 'dau',
    width: 120,
    checked: true,
    customRender: (text, record, index) => {
      if (record.dau || record.dau === 0) {
        return numFormat(record.dau, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '人均收益',
    dataIndex: 'arpu',
    width: 120,
    checked: true,
    customRender: (text, record, index) => {
      if (record.arpu || record.arpu === 0) {
        return numFormat(record.arpu, 4, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '请求',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'request' },
    checked: false,
    dataIndex: 'request',
    customRender: (text, record, index) => {
      if (record.request || record.request === 0) {
        return record.request
      } else {
        return '--'
      }
    }
  },
  {
    title: '填充',
    sorter: true,
    width: 120,
    checked: false,
    slots: { title: 'response' },
    dataIndex: 'response',
    customRender: (text, record, index) => {
      if (record.response || record.response === 0) {
        return numFormat(record.response, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '填充率',
    width: 120,
    checked: false,
    slots: { title: 'requestFilledRate' },
    dataIndex: 'requestFilledRate',
    customRender: (text, record, index) => {
      if (record.requestFilledRate || record.requestFilledRate === 0) {
        return numFormat(record.requestFilledRate, 3, '') + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '展示',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'impress' },
    dataIndex: 'impress',
    checked: true,
    customRender: (text, record, index) => {
      if (record.impress || record.impress === 0) {
        return numFormat(record.impress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'click' },
    dataIndex: 'click',
    checked: true,
    customRender: (text, record, index) => {
      if (record.click || record.click === 0) {
        return numFormat(record.click, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击率',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'clickRate' },
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      if (record.clickRate || record.clickRate === 0) {
        return record.clickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: 'eCPM',
    sorter: true,
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'ecpm' },
    dataIndex: 'ecpm',
    customRender: (text, record, index) => {
      if (record.ecpm || record.ecpm === 0) {
        return numFormat(record.ecpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '收益',
    sorter: true,
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'revenue' },
    dataIndex: 'revenue',
    customRender: (text, record, index) => {
      if (record.revenue || record.revenue === 0) {
        return numFormat(record.revenue, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '利润率',
    width: 120,
    dataIndex: 'profitRatio',
    checked: true,
    customRender: (profitRatio, record, index) => {
      if (record.profitRatio || record.profitRatio === 0) {
        return record.profitRatio + '%'
      } else {
        return '--'
      }
    }
  }
]
// 广告位
//    数据指标
export const Advertising = [
  {
    title: '请求',
    sorter: true,
    width: 120,
    checked: false,
    scopedSlots: { customRender: 'request' },
    dataIndex: 'request',
    customRender: (text, record, index) => {
      if (record.request || record.request === 0) {
        return numFormat(record.request, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '填充',
    sorter: true,
    checked: false,
    width: 120,
    slots: { title: 'response' },
    dataIndex: 'response',
    customRender: (text, record, index) => {
      if (record.response || record.response === 0) {
        return numFormat(record.response, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '填充率',
    width: 120,
    checked: false,
    slots: { title: 'requestFilledRate' },
    dataIndex: 'requestFilledRate',
    customRender: (text, record, index) => {
      if (record.requestFilledRate || record.requestFilledRate === 0) {
        return record.requestFilledRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '展示',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'impress' },
    dataIndex: 'impress',
    checked: true,
    customRender: (text, record, index) => {
      if (record.impress || record.impress === 0) {
        return numFormat(record.impress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'click' },
    dataIndex: 'click',
    checked: true,
    customRender: (text, record, index) => {
      if (record.click || record.click === 0) {
        return numFormat(record.click, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击率',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'clickRate' },
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      if (record.clickRate || record.clickRate === 0) {
        return record.clickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: 'eCPM',
    sorter: true,
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'ecpm' },
    dataIndex: 'ecpm',
    customRender: (text, record, index) => {
      if (record.ecpm || record.ecpm === 0) {
        return numFormat(record.ecpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '收益',
    sorter: true,
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'revenue' },
    dataIndex: 'revenue',
    customRender: (text, record, index) => {
      if (record.revenue || record.revenue === 0) {
        return numFormat(record.revenue, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '利润率',
    width: 120,
    dataIndex: 'profitRatio',
    checked: true,
    customRender: (profitRatio, record, index) => {
      if (record.profitRatio || record.profitRatio === 0) {
        return record.profitRatio + '%'
      } else {
        return '--'
      }
    }
  }
]
//    媒体指标
export const mediaAdvertising = [
  {
    title: '媒体请求',
    width: 120,
    scopedSlots: { customRender: 'settleRequest' },
    dataIndex: 'settleRequest',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleRequest || record.settleRequest === 0) {
        return numFormat(record.settleRequest, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体填充',
    width: 120,
    checked: false,
    slots: { title: 'settleResponse' },
    dataIndex: 'settleResponse',
    customRender: (text, record, index) => {
      if (record.settleResponse || record.settleResponse === 0) {
        return numFormat(record.settleResponse, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体填充率',
    width: 120,
    checked: false,
    slots: { title: 'settleRequestFilledRate' },
    dataIndex: 'settleRequestFilledRate',
    customRender: (text, record, index) => {
      if (record.settleRequestFilledRate || record.settleRequestFilledRate === 0) {
        return record.settleRequestFilledRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体展示',
    width: 120,
    scopedSlots: { customRender: 'settleImpress' },
    dataIndex: 'settleImpress',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleImpress || record.settleImpress === 0) {
        return numFormat(record.settleImpress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击',
    width: 120,
    scopedSlots: { customRender: 'settleClick' },
    dataIndex: 'settleClick',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleClick || record.settleClick === 0) {
        return numFormat(record.settleClick, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击率',
    width: 120,
    checked: false,
    scopedSlots: { customRender: 'settleClickRate' },
    dataIndex: 'settleClickRate',
    customRender: (text, record, index) => {
      if (record.settleClickRate || record.settleClickRate === 0) {
        return record.settleClickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体eCPM',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleEcpm' },
    dataIndex: 'settleEcpm',
    customRender: (text, record, index) => {
      if (record.settleEcpm || record.settleEcpm === 0) {
        return numFormat(record.settleEcpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体收益',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleRevenue' },
    dataIndex: 'settleRevenue',
    customRender: (text, record, index) => {
      if (record.settleRevenue || record.settleRevenue === 0) {
        return numFormat(record.settleRevenue, 3, '')
      } else {
        return '--'
      }
    }
  }
]
// 广告平台
//    数据指标
export const Platform = [
  {
    title: '展示',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'impress' },
    dataIndex: 'impress',
    checked: true,
    customRender: (text, record, index) => {
      if (record.impress || record.impress === 0) {
        return numFormat(record.impress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击',
    sorter: true,
    width: 120,
    scopedSlots: { customRender: 'click' },
    dataIndex: 'click',
    checked: true,
    customRender: (text, record, index) => {
      if (record.click || record.click === 0) {
        return numFormat(record.click, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '点击率',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'clickRate' },
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      if (record.clickRate || record.clickRate === 0) {
        return record.clickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: 'eCPM',
    sorter: true,
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'ecpm' },
    dataIndex: 'ecpm',
    customRender: (text, record, index) => {
      if (record.ecpm || record.ecpm === 0) {
        return numFormat(record.ecpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '收益',
    width: 120,
    sorter: true,
    checked: true,
    scopedSlots: { customRender: 'revenue' },
    dataIndex: 'revenue',
    customRender: (text, record, index) => {
      if (record.revenue || record.revenue === 0) {
        return numFormat(record.revenue, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '利润率',
    width: 120,
    dataIndex: 'profitRatio',
    checked: true,
    customRender: (profitRatio, record, index) => {
      if (record.profitRatio || record.profitRatio === 0) {
        return record.profitRatio + '%'
      } else {
        return '--'
      }
    }
  }
]
//    媒体指标
export const mediaPlatform = [
  {
    title: '媒体展示',
    width: 120,
    scopedSlots: { customRender: 'settleImpress' },
    dataIndex: 'settleImpress',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleImpress || record.settleImpress === 0) {
        return numFormat(record.settleImpress, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击',
    width: 120,
    scopedSlots: { customRender: 'settleClick' },
    dataIndex: 'settleClick',
    checked: false,
    customRender: (text, record, index) => {
      if (record.settleClick || record.settleClick === 0) {
        return numFormat(record.settleClick, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体点击率',
    width: 120,
    checked: false,
    scopedSlots: { customRender: 'settleClickRate' },
    dataIndex: 'settleClickRate',
    customRender: (text, record, index) => {
      if (record.settleClickRate || record.settleClickRate === 0) {
        return record.settleClickRate + '%'
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体eCPM',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleEcpm' },
    dataIndex: 'settleEcpm',
    customRender: (text, record, index) => {
      if (record.settleEcpm || record.settleEcpm === 0) {
        return numFormat(record.settleEcpm, 3, '')
      } else {
        return '--'
      }
    }
  },
  {
    title: '媒体收益',
    width: 120,
    checked: true,
    scopedSlots: { customRender: 'settleRevenue' },
    dataIndex: 'settleRevenue',
    customRender: (text, record, index) => {
      if (record.settleRevenue || record.settleRevenue === 0) {
        return numFormat(record.settleRevenue, 3, '')
      } else {
        return '--'
      }
    }
  }
]
export const radioArr = [
  { name: '应用', value: '1' },
  { name: '广告位', value: '2' },
  { name: '广告平台', value: '3' }
]
export const auditStsList = [
  { name: '已发布', id: 'A' },
  { name: '部分已发布', id: 'P' },
  { name: '未发布', id: 'S' }
]

export const batchOption = [
  { id: '0', name: '配置' },
  { id: '1', name: '发布' },
  { id: '2', name: '回收' }
]

// console.log(numFormat(0.4, 4, ''))
// console.log(numFormat(0.04, 4, ''))
// console.log(numFormat(0.004, 4, ''))
// console.log(numFormat(0.0004, 4, ''))
// console.log(numFormat(0.00004, 4, ''))

// console.log(numFormat(0.1, 4, ''))
// console.log(numFormat(0.14, 4, ''))
// console.log(numFormat(0.14, 4, ''))
// console.log(numFormat(0.1114, 4, ''))
// console.log(numFormat(0.11114, 4, ''))

// console.log(numFormat(0.01004, 4, ''))
